import { makeStyles } from "@material-ui/core";

export const styles = makeStyles({
  titleContainer: {
    textAlign: "center",
    position: "relative",
    background: "#fff",
    marginTop: "8%",
    "@media(max-width: 992px)": {
      //  height: "calc(100vw * 205 / 375)",
      paddingTop: "calc(100vw * 180 / 375)",
    },
  },
  title: {
    minHeight: "0vw",
    fontSize: "calc(100vw * 41 / 1366)",
    textAlign: "center",
    color: "#dd0b1c",
    position: "relative",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    fontFamily: "TCCC-UnityCondensed",
    fontWeight: "bold",
    "@media(max-width: 992px)": {
      fontSize: "calc(100vw * 22 / 375)",
    },
  },
  subTitle: {
    width: "calc(100vw * 680 / 1366)",
    margin: "0 auto",
    textAlign: "center",
    marginBottom: "calc(100vw * 44 / 1366)",
    lineHeight: "calc(100vw * 40 / 1366)",
    "@media(max-width: 992px)": {
      marginBottom: "calc(100vw * 20 / 375)",
      width: "calc(100vw * 250 / 375)",
    },
  },
  fontSubTitle: {
    letterSpacing: 1,
    fontSize: "calc(100vw * 34 / 1366)",
    lineHeight: "calc(100vw * 40 / 1366)",
    color: "black",
    opacity: 1,
    fontFamily: "TCCC-UnityCondensed",
    textTransform: "lowercase",
    "&::first-letter": {
      textTransform: "uppercase",
    },

    "@media(max-width: 992px)": {
      fontSize: "calc(100vw * 18 / 375)",
      marginTop: "calc(100vw * 12 / 375)",
      lineHeight: "calc(100vw * 20 / 375)",
    },
  },

  divider: {
    display: "flex",
    justifyContent: "center",
    alignContent: "center",
    marginTop: "calc(100vw * 50 / 1366)",
    marginBottom: "calc(100vw * 30 / 1366)",
    "@media(max-width: 992px)": {
      marginBottom: "calc(100vw * 8 / 375)",
      marginTop: "calc(100vw * 20 / 375)",
    },
  },

  video: {
    display: "flex",
    justifyContent: "center",
    alignContent: "center",
    marginTop: "calc(100vw * 50 / 1366)",
    marginBottom: "calc(100vw * 30 / 1366)",
    "@media(max-width: 992px)": {
      marginBottom: "calc(100vw * 8 / 375)",
      marginTop: "calc(100vw * 20 / 375)",
    },
  },

  shadow: {
    boxShadow: "-10px 5px 25px gray",
    marginTop: "calc(100vw * 10 / 1366)",
    width: "calc(100vw * 472 / 1366)",
    height: "calc(100vw * 265 / 1366)",
    "@media(max-width: 992px)": {
      width: "calc(100vw * 283 / 375)",
      height: "calc(100vw * 159 / 375)",
      boxShadow: "-6px 4px 16px gray",
    },
  },
});

export default styles;
