import React from "react";
import { Logo, LogoFooter } from "../../assets/img";
import "./styles.css";

const Template = ({ children, history, hideFooterDivider }) => {
  return (
    <div className="templateContainer">
      <div className="header">
        <Logo className="logo" onClick={() => history.push("/")} />
        <div className="linksBox">
          <div className="link" onClick={() => history.push("/")}>
            Home
          </div>
          <div className="link" onClick={() => history.push("/registrate")}>
            Registrate
          </div>
          <div className="link" onClick={() => history.push("/instructivo")}>
            Instructivo
          </div>

          <div className="link" onClick={() => history.push("/cuidado")}>
            Cuidado <br /> de envases
          </div>
          <div className="link" onClick={() => history.push("/promo")}>
            Promo <br /> QR
          </div>
        </div>
        <div className="linksBoxMobile">
          <div className="link" onClick={() => history.push("/")}>
            Home
          </div>
          <div className="link" onClick={() => history.push("/registrate")}>
            Registrate
          </div>
          <div className="link" onClick={() => history.push("/instructivo")}>
            Instructivo
          </div>
          <div className="link" onClick={() => history.push("/cuidado")}>
            Cuidado <br /> de envases
          </div>
          <div className="link" onClick={() => history.push("/promo")}>
            Promo <br /> QR
          </div>
        </div>
      </div>
      {children}
      <footer className={hideFooterDivider && "hideFooterDivider"}>
        <div class="container">
          <div class="logo">
            <LogoFooter onClick={() => history.push("/")} />
          </div>
          <div class="menu">
            <h2>Contact center</h2>
            <ul>
              <li>
                <a href="tel:08109998888">0810-999-8888</a>
              </li>
              <li>
                <a href="tel:46308888">4630-8888</a>
              </li>
              <li>
                <a href="mailto:callcenter.ar@kof.com.mx">
                  callcenter.ar@kof.com.mx
                </a>
              </li>
            </ul>
          </div>
          <div class="copy">
            <div>2021 Todos los derechos reservados</div>

            <div
              className="link"
              onClick={() => history.push("/term")}
              style={{ cursor: "pointer" }}
            >
              Términos y Condiciones
            </div>
          </div>
        </div>
      </footer>
    </div>
  );
};

export { Template };
