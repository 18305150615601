import React from "react";
import { useHistory } from "react-router-dom";
import styles from "./styles";
import ButtonLoading from "../../components/newButton/useButton";
//import { iconoCanal } from "../../assets/screens";
import { Template } from "../../components/index";

const SuccesMessageScreen = (pros) => {
  const props = {
    width: 48,
  };
  const history = useHistory();
  const classes = styles(props);

  return (
    <Template history={history}>
      <div className={classes.titleContainer}>
        <p className={classes.title}>
          <strong>BIENVENIDO:</strong>
          <br />
          YA SOS PARTE!
        </p>
      </div>

      <div className={classes.textContainer}>
        <p>
          UNA VEZ ENVIADOS LOS DATOS, AUTOMÁTICAMENTE, RECIBIRÁS UN MENSAJE DE
          MENSAJE DE TEXTO CON UN USUARIO Y CONTRASEÑA PROVISORIOS. SI ÉSTO NO
          SUCEDIERA DENTRO DE LOS 5 MINUTOS, VERIFICA QUE TODOS LOS DATOS
          INGRESADOS SEAN CORRECTOS, O VOLVÉ A INGRESARLOS.
        </p>

        <div className={classes.modalButtonContainer}>
          <div className={classes.modalButtonCode}>
            <ButtonLoading
              text="VOLVER AL INICIO"
              onClick={() => history.push("/")}
              //width={"calc(100vw * 260 / 1366)"}
            />
          </div>
        </div>
      </div>
    </Template>
  );
};

export default SuccesMessageScreen;
