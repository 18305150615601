import { makeStyles } from '@material-ui/core';
import { BACKGROUND } from '../../assets/screens';

export const styles = makeStyles({
  container: {
    backgroundImage: `url(${BACKGROUND})`,
    display: 'flex',
    flexDirection: 'row',
    height: '100%',
    "@media(max-width: 992px)": {
      minHeight: 600,
      flexDirection: 'column',
      paddingLeft: '10vw'
    }
  },
  label: {
    fontSize: '1.5vw',
    margin: 0,
    fontFamily: 'BebasNeue-Regular',
    color: '#FFFFFF',
    "@media(max-width: 992px)": {
      fontSize: '7vw',
    }
  },
  title: {
    display: 'flex',
    flex: 1,
    alignItems: 'center',
    margin: 0,
    fontSize: '2.2vw',
    lineHeight: 0.9,
    fontFamily: 'BebasNeue-Regular',
    color: '#FFFFFF',
    "@media(max-width: 992px)": {
      fontSize: '10vw',
    }
  },
  welcomeText: {
    fontSize: '4vw',
    fontFamily: 'BebasNeue-Regular',
    color: '#FFFFFF',
    margin: 0,
    "@media(max-width: 992px)": {
      fontSize: '12vw',
    }
  },
  subtitle: {
    display: 'flex',
    flex: 1,
    alignItems: 'center',
    fontSize: '1.5vw',
    lineHeight: 0.9,
    fontFamily: 'BebasNeue-Regular',
    color: '#FFFFFF',
    margin: 0,
    "@media(max-width: 992px)": {
      fontSize: '7vw',
    }
  },
  input: {
    fontSize: '1.2vw',
    height: 35,
    width: '10vw',
    backgroundColor: '#FFFFFF',
    borderRadius: 20,
    border: 0,
    marginRight: 5,
    color: 'gray',
    textAlign: 'center',
    "@media(max-width: 992px)": {
      width: '-webkit-fill-available',
      fontSize: '4vw',
    }
  },
  inputContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'end',
    alignItems: 'flex-end',
    "@media(max-width: 992px)": {
      alignItems: 'flex-start',
    }
  },
  welcome_container: {
    display: 'flex',
    flex: 1,
    justifyContent: 'flex-end',
    paddingTop: '18vh',
    paddingBottom: '14vh',
    "@media(max-width: 992px)": {
      padding: 'unset',
      justifyContent: 'flex-start',
      alignItems: 'flex-end'
    }
  },
  line: {
    backgroundColor: '#FFFFFF',
    width: '2px',
    margin: '14vh 2vw',
    "@media(max-width: 992px)": {
      height: '2px',
      width: '90vw',
      margin: '2vh 0',
    }
  },
  subTitle: {
    fontSize: '2vw',
    "@media(max-width: 992px)": {
    }
  },
  icon: {
    width: '13vw',
    "@media(max-width: 992px)": {
      width: '45vw',
    }
  },
  icon_container: {
    display: 'flex',
    flex: 1,
    alignItems: 'end',
  },
  form: {
    display: 'flex',
    flex: 2.5,
    flexDirection: 'column',
    "@media(max-width: 992px)": {
      flex: 4,
    }
  },
  code_input_container: {
    display: 'flex',
    flex: 1.2,
    flexDirection: 'column',
    justifyContent: 'center',
    margin: 0,
    "@media(max-width: 992px)": {
      flex: 1,
    }
  },
  flex_container_1_c: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    paddingTop: '18vh',
    paddingBottom: '18vh',
    "@media(max-width: 992px)": {
      flex: 5,
      padding: '0 10vw 0 0',
    }
  },
  buttonContainer: {
    display: 'flex',
    flex: 1,
    alignItems: 'flex-start',
  },
  error: {
    fontFamily: 'BebasNeue-Regular',
    fontSize: 12,
    color: 'black',
    margin: 0,
    "@media(max-width: 992px)": {
      fontSize: '2vw',
    }
  }
})


export default styles