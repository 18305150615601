import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";
import { useParams, useHistory } from "react-router-dom";
import axios from "axios";
import styles from "./styles";
import ButtonLoading from "../../components/button/useButton";
import Toast from "../../components/alert/useAlert";
import { iconoCanal } from "../../assets/screens";

const VerifyCodeScreen = () => {
  const [alert, setAlert] = useState({ title: "", show: false, message: "" });
  const [loading, setLoading] = useState(false);
  const { clientFemsa, numberPhone, franchise } = useParams();
  const test = useParams();
  const history = useHistory();
  const classes = styles();

  const { register, errors, handleSubmit } = useForm({
    reValidateMode: "onSubmit",
  });

  console.log(test, "test");

  const onSubmit = (data, e) => {
    setLoading(true);
    const { phoneCode } = data;

    axios
      .put(
        `https://us-central1-canal-coca-cola.cloudfunctions.net/app/api/punto-venta/update-phone/${clientFemsa}/${franchise}/${phoneCode}/${numberPhone}`
      )
      .then(() => {
        setAlert({
          title: "Confirmado!",
          show: true,
          message: "Número telefónico actualizado con éxito.",
        });
        setTimeout(() => {
          history.push("/successMessage");
        }, 3000);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        setAlert({
          title: "Error",
          show: true,
          message: "El código es erróneo",
        });
        e.target.reset();
      });
  };

  return (
    <div className={classes.container}>
      <div className={classes.welcome_container}>
        <p className={classes.welcomeText}>BIENVENIDO A</p>
      </div>

      <div className={classes.line}></div>

      <div className={classes.flex_container_1_c}>
        <div className={classes.icon_container}>
          <img src={iconoCanal} className={classes.icon} alt="" />
        </div>

        <p className={classes.title}>
          ESTÁS A UN PASO <br /> DE SER PARTE!
        </p>

        <form className={classes.form} onSubmit={handleSubmit(onSubmit)}>
          <div className={classes.code_input_container}>
            <p className={classes.label}>
              INGRESÁ EL CÓDIGO QUE RECIBISTE <br /> POR MENSAJE DE TEXTO, AQUÍ
            </p>
            <input
              className={classes.input}
              name="phoneCode"
              type="number"
              ref={register({
                required: { value: true, message: "Campo Obligatorio" },
                valueAsNumber: { value: true },
              })}
            />
            <ErrorMessage
              errors={errors}
              name="phoneCode"
              render={({ message }) => (
                <p className={classes.error}>{message}</p>
              )}
            />
          </div>

          <div className={classes.buttonContainer}>
            <ButtonLoading loading={loading} text="VALIDAR" />
          </div>
        </form>

        {alert.show && (
          <Toast
            title={alert.title}
            message={alert.message}
            timer={() => setAlert(false)}
          />
        )}
      </div>
    </div>
  );
};

export default VerifyCodeScreen;
