import React from "react";
// import instructive from '../../assets/screens/instructive.jpg'
import "./styles.css";
import { Template } from "../../components";
import {
  CanalLogo,
  AndroidGP,
  IosPS,
  Divider,
  LogoFooter,
} from "../../assets/img";

const InstructiveScreen = ({ history }) => (
  <Template history={history} hideFooterDivider>
    <div className="instructivo">
      <main>
        <section class="page-title">
          <h1>BIENVENIDO A</h1>
          <div class="content-box">
            <CanalLogo className="canalLogo" />
            <h2 className="text">ya está un paso más cerca de formar parte.</h2>
          </div>
        </section>
        <section class="instructivo-box">
          <div class="section-title">
            <h2>¿CÓMO DESCARGAR LA APP?</h2>
          </div>
          <div class="instructions">
            <div class="content">
              <ul>
                <li>
                  <div class="item">
                    <div class="number">
                      <div class="num">1</div>
                    </div>
                    <div class="text-box">
                      <p>Ir a la aplicación</p>
                      <ul>
                        <li>
                          <AndroidGP />
                        </li>
                        <li>
                          <IosPS />
                        </li>
                      </ul>
                    </div>
                  </div>
                </li>
                <li>
                  <div class="item">
                    <div class="number">
                      <div class="num">2</div>
                    </div>
                    <div class="text-box">
                      <p>
                        Buscar: <strong>Canal Coca-Cola Clientes</strong>
                      </p>
                    </div>
                  </div>
                </li>
                <li>
                  <div class="item">
                    <div class="number">
                      <div class="num">3</div>
                    </div>
                    <div class="text-box">
                      <p>
                        Descargar la <strong>App</strong>
                      </p>
                    </div>
                  </div>
                </li>
                <li>
                  <div class="item">
                    <div class="number">
                      <div class="num">4</div>
                    </div>
                    <div class="text-box">
                      <p>
                        Ingresar con el
                        <strong> usuario y contraseña</strong> compartido por
                        <strong> Mensaje de texto</strong>
                      </p>
                    </div>
                  </div>
                </li>
                <li>
                  <div class="item">
                    <div class="number">
                      <div class="num">5</div>
                    </div>
                    <div class="text-box">
                      <p>
                        Validar con un <strong>mail y contraseña</strong> que
                        elija
                      </p>
                    </div>
                  </div>
                </li>
                <li>
                  <div class="item">
                    <div class="number">
                      <div class="num">6</div>
                    </div>
                    <div class="text-box">
                      <p>
                        Listo! ya está formando parte del
                        <strong> Canal Coca-Cola</strong>
                      </p>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </section>
        <section class="wait">
          <div class="container">
            <div class="divider">
              <Divider />
            </div>
            <div class="content-box">
              <div class="text">
                <p>Te esperamos para que seas parte del</p>
              </div>
              <div class="logo">
                <LogoFooter />
              </div>
            </div>
          </div>
        </section>
      </main>
    </div>
  </Template>
);

export default InstructiveScreen;
