import React from 'react'
import styles from './styles'
import spinner from '../../assets/spinner.gif'

const useButtonLoader = ({ loading, text, onClick }) => {
    const classes = styles()

    return (
        <button disabled={loading} className={classes.button} onClick={onClick}>
            {loading ? <span className="oneSpan"><img style={{ width: '30px' }} alt={spinner} src={spinner} /></span> : <span className="twoSpan">{text}</span>}
        </button>
    )

}
export default useButtonLoader;