import { makeStyles } from "@material-ui/core";

export const styles = makeStyles({
  titleContainer: {
    textAlign: "center",
    position: "relative",
    background: "#fff",
    height: "calc(100vw * 201 / 1366)",
    "@media(max-width: 992px)": {
      height: "calc(100vw * 180 / 375)",
      paddingTop: "calc(100vw * 120 / 375)",
    },
  },
  title: {
    fontSize: "calc(100vw * 51 / 1366)",
    color: "#dd0b1c",
    position: "relative",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    fontFamily: "TCCC-UnityCondensed",
    fontWeight: "bold",
    "@media(max-width: 992px)": {
      fontSize: "calc(100vw * 32 / 375)",
    },
  },

  textContainer: {
    paddingTop: "calc(100vw * 40 / 1366)",
    fontSize: "calc(100vw * 24 / 1366)",
    //lineHeight: "calc(100vw * 40 / 1366)",
    color: "#dd0b1c",
    //opacity: 1,
    fontFamily: "Bebas Neue",
    width: "calc(100vw * 400 / 1366)",
    margin: "0 auto",
    lineHeight: 1,
    textAlign: "center",
    "@media(max-width: 992px)": {
      fontSize: "calc(100vw * 24 / 375)",
      width: "calc(100vw * 310 / 375)",
    },
  },

  modalButtonContainer: {
    width: "calc(100vw * 400 / 1366)",
    display: "flex",
    justifyContent: "center",
    marginTop: "calc(100vw * 30 / 1366)",
    "@media(max-width: 992px)": {
      width: "100%",
      height: "calc(100vw * 50 / 375)",
    },
  },
  modalButtonCode: {
    width: "calc(100vw * 240 / 1366)",
    display: "flex",
    justifyContent: "center",
    "@media(max-width: 992px)": {
      width: "100%",
      height: "calc(100vw * 50 / 375)",
    },
  },
});

export default styles;
