import { makeStyles } from "@material-ui/styles";

export const styles = makeStyles({
  button: {
    cursor: "pointer",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    //width: ({ width }) => width,
    height: "100%",
    background: "#dd0b1c",
    borderRadius: 50,
    border: "1px solid #dd0b1c",
    fontSize: "calc(100vw * 18 / 1366)",
    lineHeight: "calc(100vw * 40 / 1366)",
    letterSpacing: "0.25em",
    color: "#ffffff",
    textTransform: "uppercase",
    fontFamily: "Bebas Neue Book",
    "@media(max-width: 992px)": {
      fontSize: "calc(100vw * 25 / 375)",
    },
  },
});

export default styles;
