import React from "react";
import style from "./styles";
import { useHistory } from "react-router-dom";
import { Template } from "../../components";
import { Divider } from "../../assets/img";

const PromoQR = () => {
  const history = useHistory();
  const classes = style();

  return (
    <Template history={history}>
      <div className={classes.titleContainer}>
        <p className={classes.title}>PROMO QR</p>
      </div>
      <div className={classes.subTitle}>
        <p className={classes.fontSubTitle}>
          En este video vas a saber cómo adherirte para ser{" "}
          <span style={{ textTransform: "uppercase" }}>CENTRO DE CANJE </span> y
          sumar más clientes que compren{" "}
          <span style={{ textTransform: "uppercase" }}>RETORNABLES </span>con la{" "}
          <span style={{ textTransform: "uppercase" }}> PROMO QR</span>
        </p>
      </div>
      <div className={classes.divider}>
        <Divider
          style={{
            width: "60%",
            height: 20,
          }}
        />
      </div>

      <div className={classes.video}>
        <iframe
          className={classes.shadow}
          src="https://www.youtube.com/embed/q3v8x4XON14"
          title="YouTube video player"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowfullscreen
        ></iframe>
      </div>

      <div className={classes.divider}>
        <Divider
          style={{
            width: "60%",
            height: 20,
          }}
        />
      </div>
    </Template>
  );
};

export default PromoQR;
