import React from "react";
import style from "./styles";
import { useHistory } from "react-router-dom";
import { Template } from "../../components";
import { Divider } from "../../assets/img";

const CuidadoScreen = () => {
  const history = useHistory();
  const classes = style();

  return (
    <Template history={history}>
      <div className={classes.titleContainer}>
        <p className={classes.title}>CUIDADO DE ENVASES</p>
      </div>
      <div className={classes.subTitle}>
        <p className={classes.fontSubTitle}>
          En estos dos videos, vas a encontrar las pautas para contribuir a un
          buen manejo, tanto del envase como del producto.
        </p>
      </div>
      <div className={classes.divider}>
        <Divider
          style={{
            width: "60%",
            height: 20,
          }}
        />
      </div>

      <div className={classes.video}>
        <iframe
          className={classes.shadow}
          src="https://www.youtube.com/embed/e4SFCJ7e23k"
          title="YouTube video player"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowfullscreen
        ></iframe>
      </div>
      <div className={classes.video}>
        <iframe
          className={classes.shadow}
          src="https://www.youtube.com/embed/1B4ymeGyyiY"
          title="YouTube video player"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowfullscreen
        ></iframe>
      </div>
      <div className={classes.divider}>
        <Divider
          style={{
            width: "60%",
            height: 20,
          }}
        />
      </div>
    </Template>
  );
};

export default CuidadoScreen;
