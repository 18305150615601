import React, { useEffect } from 'react';
import './styles.css'
import errorIcon from '../../assets/error.svg'

const Toast = ({ message, timer, title }) => {

    useEffect(() => {
        setTimeout(() => timer(), 4000);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    return (
        <>
            <div className={`notification-container bottom-right`}>
                <div className={`notification toast bottom-right`}>
                    <div className="notification-image">
                        <img src={errorIcon} alt="" />
                    </div>
                    <div>
                        <p className="notification-message">{message}</p>
                    </div>
                </div>
            </div>
        </>
    );
}



export default Toast;