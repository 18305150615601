import React from "react";
import "./styles.css";

import {
  Icon1,
  Icon2,
  Icon3,
  Icon4,
  Icon5,
  Icon6,
} from "../../assets/img/index";
import { Template } from "../../components";

const HomeScreen = ({ history }) => {
  console.log(history);
  return (
    <Template history={history}>
      <div className='content'>
        <div className='titleSection'>
          <h1 className='title'>Sumate a canal coca-cola</h1>
        </div>
        <div className='flexGrid'>
          <div className='row'>
            <div className='col'>
              <div className='colContent'>
                <div className='imgBox'>
                  <Icon1 className='img' />
                </div>
                <div className='cardText'>
                  Un Canal de comunicación directo e instantáneo para estar
                  siempre conectados.
                </div>
              </div>
            </div>
            <div className='col'>
              <div className='colContent'>
                <div className='imgBox'>
                  <Icon2 className='img' />
                </div>
                <div className='cardText'>
                  Promociones exclusivas
                  <br />
                  para vos.
                </div>
              </div>
            </div>
            <div className='col'>
              <div className='colContent'>
                <div className='imgBox'>
                  <Icon3 className='img' />
                </div>
                <div className='cardText'>Lanzamientos y comerciales.</div>
              </div>
            </div>
          </div>
          <div className='row'>
            <div className='col'>
              <div className='colContent'>
                <div className='imgBox'>
                  <Icon4 className='img' />
                </div>
                <div className='cardText'>
                  Novedades de portafolio y ejecución.
                </div>
              </div>
            </div>
            <div className='col'>
              <div className='colContent'>
                <div className='imgBox'>
                  <Icon5 className='img' />
                </div>
                <div className='cardText'>Pop digital.</div>
              </div>
            </div>
            <div className='col'>
              <div className='colContent'>
                <div className='imgBox'>
                  <Icon6 className='img' />
                </div>
                <div className='cardText'>Y muchísimo más!</div>
              </div>
            </div>
          </div>
        </div>
        <div class='boxControls'>
          <div
            className='btn btn-primary'
            onClick={() => history.push("/registrate")}
          >
            Registrate
          </div>
        </div>
      </div>
    </Template>
  );
};

export default HomeScreen;
