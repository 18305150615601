import React from "react";
import "./styles.css";

import { Template } from "../../components";

const TermsScreen = ({ history }) => {
  return (
    <Template history={history}>
      <div className="content">
        <div className="titleSection">
          <h1 className="title">
            Al utilizar el Sitio, acepta las siguientes condiciones de uso:
          </h1>
        </div>
        <div className="flexGrid">
          <h5 className="h5">
            Su acceso a y uso del sitio, incluidos el contenido, los servicios y
            el software que se proporcionan en el mismo (en conjunto referidos
            como el “Sitio”) están sujetos a las siguientes condiciones de uso,
            así como a todas las leyes y reglamentaciones aplicables
            (Condiciones). Si no acepta las Condiciones de uso, sin limitaciones
            ni modificaciones, le invitamos a que abandone el Sitio.
          </h5>

          <p className="p">
            1. Propiedad del contenido El Sitio y todo su contenido, incluidos,
            entre otros, todos los textos y las imágenes y los derechos de autor
            de los mismos (Contenidos o Contenido según corresponda) pertenecen
            a sus respectivos propietarios con todos los derechos reservados, a
            menos que se indique lo contrario. Cualquier Contenido, se trate de
            una marca comercial, logotipo o marca servicio, también se considera
            una marca comercial registrada o no registrada nuestra o de
            terceros. Queda terminantemente prohibido el uso del Contenido de
            cualquier forma que infrinja las presentes Condiciones de uso o sin
            la autorización escrita del propietario respectivo.{" "}
          </p>
          <p className="p">
            2. Uso del Sitio El usuario del Sitio no podrá: distribuir,
            modificar, copiar, transmitir, mostrar, volver a utilizar,
            reproducir, publicar, otorgar licencias, crear trabajos derivados
            de, transferir, vender o utilizar de cualquier otra forma el
            Contenido de la presente sin el consentimiento por escrito del
            administrador del sitio y/o sus propietarios. Está prohibido usar el
            Sitio para enviar o transmitir cualquier material ilegal,
            amenazante, falso, engañoso, abusivo, acosador, injurioso,
            difamatorio, vulgar, obsceno, escandaloso, provocador, pornográfico
            o irreverente, o cualquier otro material que pueda constituir un
            ilícito, un delito penal o inducir a una conducta que se pudiera
            considerar como tal, que pueda dar lugar a procesos de
            responsabilidad civil o que pudiera infringir cualquier otra ley.
            Está prohibido usar el Sitio para anunciar o llevar a cabo cualquier
            actividad comercial o explotarlo de otro modo para fines
            comerciales. Está prohibido usar el Sitio para enviar o transmitir
            cualquier virus, gusano, troyano, huevo de Pascua, bomba con
            temporizador, programa espía u otro código informático, archivo o
            programa que sea perjudicial o invasivo o que pueda o tenga por
            objeto dañar o piratear el funcionamiento de, o controlar el uso de,
            cualquier hardware, software o equipo. Está prohibido usar el Sitio
            para violar los derechos legales de otros o recoger o recopilar
            información personal de identificación acerca de los usuarios del
            Sitio. Está prohibido restringir o impedir que cualquier persona
            utilice el Sitio, así como perturbar e interferir en el
            funcionamiento del Sitio, las redes o los servidores utilizados para
            que el Sitio esté disponible y violar cualquier requisito,
            procedimiento, política o reglamentación de tales redes. Queda
            prohibida toda modificación, adaptación, traducción, ingeniería
            inversa, descompilación o desmontaje de cualquier parte del Sitio.{" "}
          </p>
          <p className="p">
            3. Software, Servicios y Contenido del usuario. Los responsables del
            sitio podrán proporcionar acceso al usuario a determinado Contenido
            especializado del Sitio, servicios interactivos a través de los
            cuales podrá mostrar o publicar información y materiales (como
            contenido y servicios, en conjunto referidos como "Servicios”), y
            determinadas herramientas de software que se puedan utilizar para
            fines varios. Los Servicios y el Software, la información y los
            materiales disponibles a través del Sitio, así como todo el software
            utilizado para el Sitio son propiedad de sus responsables. SI EL
            USUARIO DECIDE IDENTIFICARSE DE ALGÚN MODO O PUBLICAR ALGÚN TIPO DE
            INFORMACIÓN PERSONAL EN UNA PRESENTACIÓN O EN CUALQUIER CONTENIDO
            DEL USUARIO, LO HACE BAJO SU PROPIA RESPONSABILIDAD.{" "}
          </p>

          <p className="p">
            4. Registro, nombres de usuario y contraseñas Es posible que se le
            exija al usuario que se registre en el Sitio para acceder a
            determinadas zonas del mismo. En lo que se refiere a dicho registro,
            podemos negarnos a autorizar la utilización de un nombre de usuario
            (o dirección de correo electrónico) que ya esté siendo utilizado por
            otra persona; o que se pueda considerar que suplanta o se confunde
            con otra persona; o que pertenezca a otra persona; o que infrinja
            derechos de propiedad intelectual u otro tipo de derechos de
            cualquier persona; o que sea ofensivo; o que rechacemos por
            cualquier otro motivo a nuestra única discreción. El usuario es
            responsable de mantener la confidencialidad de cualquier contraseña
            que pueda usar para acceder al Sitio y acepta no transmitir su
            nombre de usuario o contraseña ni prestarlos ni trasladar de otro
            modo el uso de o acceso al Sitio a ningún tercero. El usuario es
            plenamente responsable de toda interacción con el Sitio que se
            produzca en relación con su contraseña o nombre de usuario. Acepta
            notificar inmediatamente a los responsables cualquier uso no
            autorizado de su nombre de usuario o contraseña o de cualquier otra
            violación de la seguridad en relación con su cuenta o con el Sitio y
            de asegurarse de que "cierra sesión"/sale de su cuenta en el Sitio
            (si procede) al final de cada sesión. No nos responsabilizamos de
            cualquier pérdida o daño resultante del no cumplimiento de
            cualquiera de las obligaciones anteriores.{" "}
          </p>
          <p className="p">
            5. Privacidad Los responsables harán uso de cualquier dato de
            carácter personal (como, por ejemplo, el nombre, la dirección, el
            número de teléfono o la dirección de correo electrónico) que el
            usuario transmita al Sitio por correo electrónico o de cualquier
            otra forma al solo efecto de cumplir el fin para lo cual se cargan
            dichos datos. La utilización de la plataforma implica prestar
            conformidad con dicha utilización de los datos. Cualquier otra
            comunicación o material que transmita al Sitio como, por ejemplo,
            preguntas, comentarios, sugerencias y otras cuestiones de carácter
            similar, se tratará como asunto público sin derechos de propiedad.
            No se codificará ni se aplicará ninguna otra medida de seguridad a
            las transmisiones de correo electrónico o de otro tipo. El usuario
            acepta y garantiza que cualquier información que proporcione al
            Sitio es verídica, precisa y completa, y que la mantendrá
            actualizada de forma regular. Acepta que, si proporciona información
            falsa, imprecisa, anticuada o incompleta, podemos poner fin a su
            utilización del Sitio.{" "}
          </p>

          <p className="p">
            6. Exclusión de garantías EL SITIO Y TODO EL SOFTWARE, LOS
            SERVICIOS, CONTENIDO Y CONTENIDO DEL USUARIO DISPONIBLE A TRAVÉS DEL
            SITIO ESTÁN SUJETOS A CAMBIOS Y SE PROPORCIONAN SIN GARANTÍA DE
            NINGÚN TIPO, YA SEA EXPRESA O IMPLÍCITA, INCLUIDAS LAS GARANTÍAS
            IMPLÍCITAS DE COMERCIALIZACIÓN, IDONEIDAD PARA UN FIN PARTICULAR O
            DE DERECHOS DE PROPIEDAD. Sin limitación de lo anterior, nosotros no
            garantizamos ni aseguramos que el uso que el usuario haga del Sitio,
            cualquier Software, Servicio, Contenido o Contenido del usuario no
            infringe los derechos de terceros y que nada de lo mencionado
            anteriormente sea preciso, esté completo o actualizado. Asimismo,
            con respecto a cualquier publicación, presentación y otros elementos
            similares que se puedan encontrar en el Sitio. Tampoco asumimos
            responsabilidad alguna sobre el uso de cualquier material ilegal,
            amenazante, falso, engañoso, abusivo, acosador, injurioso,
            difamatorio, vulgar, obsceno, escandaloso, provocador, pornográfico
            o irreverente, o cualquier otro material que pueda constituir un
            ilícito o delito penal o inducir a una conducta que se pudiera
            considerar como tal, que pueda dar lugar a procesos de
            responsabilidad civil y/o penal o que pudiera infringir cualquier
            otra ley y que esté incluido en cualquier ubicación del Sitio.{" "}
          </p>
          <p className="p">
            7. Limitación de responsabilidad En atención que USTED UTILIZA EL
            SITIO O CUALQUIER SOFTWARE, SERVICIO O MATERIAL DISPONIBLE A TRAVÉS
            DEL SITIO POR SU PROPIA CUENTA Y RIESGO. NOSOTROS NI NINGÚN TERCERO
            INVOLUCRADO EN LA CREACIÓN, PRODUCCIÓN O FUNCIONAMIENTO DEL SITIO,
            NI NINGUNO DE SUS RESPECTIVOS DIRECTORES, FUNCIONARIOS, EMPLEADOS,
            AGENTES, ACCIONISTAS, OTORGANTES DE LICENCIA Y REPRESENTANTES, SON
            RESPONSABLES DE NINGÚN DAÑO DIRECTO, INDIRECTO, PUNITIVO,
            INCIDENTAL, ESPECIAL, EMERGENTE, EJEMPLAR U OTRO DAÑO RESULTANTE O
            RELACIONADO CON EL USO DE ESTE SITIO, LOS SERVICIOS, EL SOFTWARE, EL
            CONTENIDO O EL CONTENIDO DEL USUARIO, SEAN DE TIPO CONTRACTUAL,
            EXTRACONTRACTUAL, POR RESPONSABILIDAD OBJETIVA O DE CUALQUIER OTRO
            TIPO, AÚN CUANDO SE HUBIERA INFORMADO DE LA POSIBILIDAD DE QUE SE
            PRODUJERAN TALES DAÑOS, INCLUIDOS, SIN LIMITARSE A, LOS DAÑOS POR
            PÉRDIDA DE BENEFICIOS, USO, DATOS, PÉRDIDA DE OTROS INTANGIBLES Y
            PÉRDIDA DE SEGURIDAD DE LA INFORMACIÓN QUE HAYA PROPORCIONADO EN
            RELACIÓN CON SU USO DEL SITIO, LOS SERVICIOS, EL SOFTWARE, EL
            CONTENIDO O EL CONTENIDO DEL USUARIO, O LA INTERCEPTACIÓN NO
            AUTORIZADA DE DICHA INFORMACIÓN POR TERCEROS, INCLUSO SI SE HA
            NOTIFICADO PREVIAMENTE TALES DAÑOS O PÉRDIDAS. EN PARTICULAR, Y SIN
            LIMITACIÓN, NI NOSTROS NI LAS ENTIDADES AFILIADAS SERÁN RESPONSABLES
            DE LOS DAÑOS QUE RESULTEN DE CUALQUIER TIPO DE USO O IMPOSIBILIDAD
            DE USO DE ESTE SITIO, LOS SERVICIOS O EL SOFTWARE O QUE DERIVEN DEL
            CONTENIDO O DEL CONTENIDO DEL USUARIO EN EL SITIO POR NOSOTROS O UN
            TERCERO. EN CASO DE DISCONFORMIDAD, LA ÚNICA SOLUCIÓN ES DEJAR DE
            UTILIZAR ESTE SITIO.{" "}
          </p>
          <p className="p">
            8. Enlaces a Sitios de terceros Es posible que el Sitio incluya
            enlaces a otros Sitios que son propiedad de o están gestionados por
            otras empresas ajenas a nosotros. Dichos enlaces se proporcionan
            exclusivamente para su conveniencia. El Administrador del sitio no
            controla el contenido, las políticas de privacidad o la seguridad de
            tales Sitios y en consecuencia no asume responsabilidad alguna al
            respecto.{" "}
          </p>
          <p className="p">
            9. Advertencia sobre el lenguaje empleado para describir hechos
            futuros Es posible que el Sitio contenga afirmaciones, valoraciones
            o pronósticos que puedan constituir "afirmaciones sobre hechos
            futuros". Por lo general, los verbos "creer", "esperar",
            "pretender", "valorar", "anticipar", "proyectar", así como otras
            expresiones de este tipo hacen alusión a afirmaciones sobre hechos
            futuros, que están sujetas a determinados riesgos e incertidumbres
            que pueden provocar que los resultados reales difieran
            significativamente de la experiencia histórica y de las expectativas
            o previsiones actuales. Consecuentemente con ello, el Sitio y sus
            responsables no garantizan resultado alguno, ni se obligan a
            actualizar o revisar periódicamente cualquier afirmación de hechos
            futuros.{" "}
          </p>
          <p className="p">
            10. Finalización El usuario acepta que el responsable del Sitio se
            reserva el derecho a dar por terminado su acceso o uso del Sitio y
            de los Servicios en cualquier momento y por cualquier razón,
            incluso, sin limitación, si considera que ha infringido o ha obrado
            en contradicción a lo estipulado en las Condiciones de uso. Si
            ocurre dicha finalización, automáticamente dejará de tener derecho a
            utilizar este Sitio y los Servicios. Asimismo, acepta que la
            finalización de su acceso a este Sitio puede realizarse sin aviso
            previo y que el administrador puede desactivar o eliminar su cuenta
            y contraseña, así como cualquier información relacionada o archivos
            incluidos y prohibir cualquier acceso posterior a dicha información
            o archivos. También acepta que el responsable o sus socios no asumen
            responsabilidad alguna por la finalización de su acceso al Sitio,
            información o archivos, ni están obligados a concederle acceso a
            dicha información o archivos.{" "}
          </p>
          <p className="p">
            11. Revisión de las condiciones Los responsables del Sitio pueden,
            cuando lo estimen oportuno, y sin llevar a cabo notificación alguna,
            revisar las Condiciones de uso mediante la actualización de las
            presentes Condiciones. Los usuarios están obligados a cumplir con
            aquello que se dicte en cualquier revisión y, por lo tanto, deben
            visitar frecuentemente esta página para revisar las Condiciones de
            uso vigentes. Pueden comprobar la fecha de revisión de estas
            Condiciones de uso en el título "ÚLTIMA ACTUALIZACIÓN" al inicio del
            presente documento. El contenido y los servicios disponibles a
            través del Sitio que no lo estaban en la fecha de la "ÚLTIMA
            ACTUALIZACIÓN" mencionada anteriormente se considerarán
            automáticamente parte de los Servicios en cuanto estén disponibles
            mediante el Sitio. Cualquier software o herramienta disponible a
            través del Sitio que no estuviera disponible en la fecha de la
            "ÚLTIMA ACTUALIZACIÓN" mencionada anteriormente, se considerará
            automáticamente el Software en lo que respecta al Sitio en cuanto
            esté disponible a través del mismo. Al utilizar cualquiera de los
            servicios o el software de este Sitio, acepta que está obligado al
            cumplimiento de las indicaciones, normas, términos y condiciones
            aplicadas a estos Servicios o Software añadidos posteriormente, que
            se incorporan por la presente a estas Condiciones de uso.{" "}
          </p>
          <p className="p">
            12. Revisión del sitio Nos reservamos el derecho de, bien sea en
            cualquier momento, ocasionalmente, de forma temporal o permanente,
            total o parcial, modificar o suspender el Sitio, los Servicios o el
            Software, con o sin previo aviso; limitar el acceso al Sitio a
            cualquier persona, área geográfica o jurisdicción que consideremos
            oportuna; aplicar tarifas de conexión al Sitio; modificar y/o
            renunciar a las tarifas de conexión al Sitio; y/u ofrecer
            posibilidades a todos o algunos de los usuarios del Sitio. Usted
            acepta que ni nosotros ni las Entidades afiliadas serán responsables
            ante usted ni ante terceras partes de las modificaciones o
            suspensiones del Sitio, ya sea total o parcialmente, o de cualquier
            Servicio, Software, Contenido, presentación, función o producto
            ofrecido a través del Sitio. Si continúa utilizando el Sitio tras
            estos cambios, se dará por supuesta su conformidad con los mismos.{" "}
          </p>
          <p className="p">
            13. Exclusión Si alguna de las disposiciones de las presentes
            Condiciones de uso resultara ser ilegal, nula o imposible de
            cumplir, quedará excluida de las Condiciones de uso y no afectará a
            la validez ni el cumplimiento de las disposiciones restantes.{" "}
          </p>
          <p className="p">
            14. Protección de datos personales Se hace saber que el titular de
            los datos personales tiene la facultad de ejercer el derecho de
            acceso a los mismos en forma gratuita y en intervalos no inferiores
            a seis meses, salvo que se acredite un interés legítimo al efecto,
            conforme lo establecido en el art. 14, inc. 3 de la Ley 25.326. La
            Dirección Nacional de Protección de Datos Personales es el Órgano de
            Control y tiene la atribución de atender las denuncias y reclamos
            que se interpongan con relación al incumplimiento de las normas
            sobre protección de datos personales.{" "}
          </p>
          <p className="p">
            15. Reclamaciones por violación de derechos de autor. La Ley de
            Derechos de Autor para el Milenio Digital (Digital Millenium
            Copyright Act) (la "DMCA") de 1998, permite el derecho al recurso de
            cualquier propietario de derechos de autor que considere que el
            material que aparece en Internet vulnera sus derechos de acuerdo con
            las leyes de derechos de autor de EE.UU. Si cree de buena fe que
            algún material del Sitio infringe sus derechos de autor, usted (o su
            representante legal) puede presentar una notificación al responsable
            del sitio en la que solicita que se elimine o se bloquee el acceso a
            ese material.
          </p>
        </div>
      </div>
    </Template>
  );
};

export default TermsScreen;
