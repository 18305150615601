import React, { useState } from "react";
import { useForm, Controller } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";
import { useHistory } from "react-router-dom";
import ButtonLoading from "../../components/newButton/useButton";
import Toast from "../../components/alert/useAlert";
import axios from "axios";
import style from "./styles";
// import Header from "../../components/header";
import { Select, MenuItem } from "@material-ui/core";
import { Template } from "../../components/index";

const ClientScreen = () => {
  const history = useHistory();
  const [showAlert, setAlert] = useState(false);
  const [showModal, setModal] = useState(false);
  //const [showMenu, setMenu] = useState(false);
  const [dataUser, setData] = useState({
    clientFemsa: "",
    numberPhone: "",
    franchise: "",
  });
  //const [franchise, setFranchise] = useState("");
  const [businessName, setBusinessName] = useState(
    "AV GRAL SAN MARTÍN 1767 AV GRAL SAN MARTÍN 1767"
  );
  const [loading, setLoading] = useState(false);
  const [loadingCode, setLoadingCode] = useState(false);
  const [showModalCode, setModalCode] = useState(false);
  const {
    register,
    formState: { errors },
    handleSubmit,
    control,
  } = useForm({
    reValidateMode: "onSubmit",
  });

  //////////
  const {
    register: register2,
    formState: { errors: errors2 },
    handleSubmit: handleSubmit2,
  } = useForm({
    reValidateMode: "onSubmit",
  });
  ///
  const classes = style();

  const onSubmit = (data, e) => {
    console.log(data, "data primer onSubmit");
    setLoading(true);
    const { clientFemsa, areaCode, number, franchise } = data;
    const numberPhone = `549${areaCode}${number}`;

    setData({ clientFemsa, numberPhone, franchise });
    axios
      .post(
        `https://us-central1-canal-coca-cola.cloudfunctions.net/app/api/punto-venta/validation-phone-code/${clientFemsa}/${franchise}`
      )
      .then((response) => {
        setBusinessName(response.data);
        setLoading(false); ////////////
        setModal(true);
        e.target.reset();
      })
      .catch((error) => {
        console.log(error, "error del primer submit");
        setLoading(false);
        setAlert(true);
      });
  };

  const modalResponse = (e) => {
    console.log(e, "eeee");
    axios
      .post(
        `https://us-central1-canal-coca-cola.cloudfunctions.net/app/api/punto-venta/send-phone-code/${dataUser.clientFemsa}/${dataUser.numberPhone}/${dataUser.franchise}`
      )
      .then(
        setModalCode(true),
        setModal(false)

        /*   history.push(
          `/verifycode/${dataUser.clientFemsa}/${dataUser.numberPhone}/${dataUser.franchise}`
        ) */
      )
      .catch((error) => {
        setLoading(false);
        setAlert(true);
        //e.target.reset()
      });
  };

  const closeModal = () => {
    setModal(false);
    setLoading(false);
  };

  /* const menuOption = (option) => {
    setMenu(false);
    setFranchise(option);
  }; */

  const franchiseOptions = [
    { value: "FEMSA", label: "FEMSA" },
    { value: "REPRESENTACIONES TIGRE", label: "REPRESENTACIONES TIGRE" },
    { value: "FERRARO", label: "FERRARO" },
    { value: "GULY", label: "GULY" },
    { value: "NORIA", label: "NORIA" },
    { value: "RUTA5", label: "RUTA5" },
    { value: "SALEMA", label: "SALEMA" },
    { value: "REBESA", label: "REBESA" },
    { value: "YAMANIL", label: "YAMANIL" },
  ];
  //////
  const onSubmitCode = (data, e) => {
    setLoadingCode(true);
    const { phoneCode } = data;
    console.log(data, "data submit code");

    axios
      .put(
        `https://us-central1-canal-coca-cola.cloudfunctions.net/app/api/punto-venta/update-phone/${dataUser.clientFemsa}/${dataUser.franchise}/${phoneCode}/${dataUser.numberPhone}`
      )
      .then(() => {
        setAlert({
          title: "Confirmado!",
          show: true,
          message: "Número telefónico actualizado con éxito.",
        });
        setTimeout(() => {
          setModalCode(false);
          history.push("/successMessage");
        }, 3000);
        setLoadingCode(false);
        setAlert(false); //ejecuta submit del otro formulario con msj de usuario no existe
        // setLoading(false);
      })
      .catch((error) => {
        console.log(error, "errro Code linea 131");
        setLoadingCode(false);
        setAlert({
          title: "Error",
          show: true,
          message: "El código es erróneo",
        });
        e.target.reset();
      });
  };

  return (
    <Template history={history}>
      <div className={classes.titleContainer}>
        <p className={classes.title}>
          <strong>BIENVENIDO:</strong>
          <br />
          ESTÁS A UN PASO DE SER PARTE!
        </p>
      </div>
      <div className={classes.subTitle}>
        <p className={classes.fontSubTitle}>COMPLETÁ LOS SIGUIENTES DATOS:</p>
      </div>
      <form key={1} className={classes.form} onSubmit={handleSubmit(onSubmit)}>
        <div className={classes.franchiseContainer}>
          <p>DISTRIBUIDOR</p>
          <Controller
            name="franchise"
            control={control}
            rules={{ required: true }}
            render={({ onChange, value, name }) => (
              <Select
                className={classes.dropdownContentOn}
                disableUnderline
                defaultValue=""
                inputProps={{ "aria-label": "Without label" }}
                name={name}
                onChange={(e) => {
                  onChange(e);
                }}
                value={value ? value : ""}
              >
                {franchiseOptions.map((option, index) => (
                  <MenuItem
                    key={index}
                    value={option.value}
                    // onClick={() => menuOption(option.value)}
                  >
                    {option.label}
                  </MenuItem>
                ))}
              </Select>
            )}
          />

          {errors.franchise && (
            <p className={classes.error}>Campo obligatorio</p>
          )}
        </div>

        <div className={classes.clientMarginBottom}>
          <div className={classes.clientNumberContainer}>
            <p className={classes.label}>
              N° DE CLIENTE{" "}
              <spam className={classes.sub_label}>
                (Lo encontras en tu factura)
              </spam>
            </p>
            {/* <p className={classes.sub_label}>(Lo encontras en tu factura)</p> */}
          </div>

          <input
            className={classes.dropdownContentOn}
            name="clientFemsa"
            type="number"
            ref={register({
              required: { value: true, message: "Campo Obligatorio" },
              valueAsNumber: { value: true },
            })}
          />
          <ErrorMessage
            className={classes.error}
            errors={errors}
            name="clientFemsa"
            render={({ message }) => <p className={classes.error}>{message}</p>}
          />
        </div>

        <p className={classes.label}>N° DE CELULAR</p>

        <div className={classes.wspContainer}>
          <div>
            <input
              className={`${classes.dropdownContentOn} ${classes.prefix_input}`}
              name="prefix"
              defaultValue="+549"
              type="text"
              readOnly
              ref={register}
            />
          </div>

          <div>
            <input
              className={`${classes.dropdownContentOn} ${classes.areacode_input}`}
              name="areaCode"
              type="number"
              ref={register({
                required: { value: true, message: "Campo Obligatorio" },
                valueAsNumber: true,
              })}
            />
            <p className={classes.movil_label}>CÓD. AREA SIN "0"</p>
            <ErrorMessage
              errors={errors}
              name="areaCode"
              render={({ message }) => (
                <p className={`${classes.error} ${classes.areacode_input}`}>
                  {message}
                </p>
              )}
            />
          </div>

          <div>
            <input
              className={`${classes.dropdownContentOn} ${classes.number_input}`}
              name="number"
              type="number"
              placeholder="Número sin 15"
              ref={register({
                required: { value: true, message: "Campo Obligatorio" },
                valueAsNumber: true,
              })}
            />
            <p className={classes.movil_label}>TELÉFONO SIN "15"</p>
            <ErrorMessage
              errors={errors}
              name="number"
              render={({ message }) => (
                <p className={classes.error}>{message}</p>
              )}
            />
          </div>
        </div>

        <div className={classes.buttonContainer}>
          <div className={classes.button}>
            <ButtonLoading
              loading={loading}
              text="ENVIAR CÓDIGO"
              //width={"calc(100vw * 210 / 1366)"}
            />
          </div>
        </div>
      </form>
      {showAlert && (
        <Toast
          title="Error"
          message="El n° de cliente ingresado no existe"
          timer={() => setAlert(false)}
        />
      )}
      {showModal && (
        <div className={classes.modal}>
          <div className={classes.modalDialog}>
            <header className={classes.modalHeader}>
              <p>Verificación de identidad</p>
            </header>
            <section className={classes.modalContent}>
              <p>
                Estimado cliente, para verificar la identidad por favor podría
                confirmarnos que, ¿Usted es el cliente{" "}
                <b className={classes.businessName}>{businessName}</b>?
              </p>
            </section>
            <footer className={classes.modalFooter}>
              <button
                className={classes.modalButton}
                onClick={() => modalResponse()}
              >
                SI
              </button>
              <button
                className={classes.modalButton}
                onClick={() => closeModal()}
              >
                NO
              </button>
            </footer>
          </div>
        </div>
      )}
      {/* //////// verificacion del codigo ////////// */}
      {showModalCode && (
        <div className={classes.modalDialogCode}>
          <div>
            <form
              key={2}
              className={classes.modalContainerCode}
              onSubmit={handleSubmit2(onSubmitCode)}
            >
              <div className={classes.codeContainer}>
                <p className={classes.labelCode}>
                  INGRESÁ EL CÓDIGO QUE RECIBISTE POR MENSAJE DE TEXTO, AQUÍ
                </p>
                <input
                  className={classes.dropdownContentOn}
                  name="phoneCode"
                  type="number"
                  ref={register2({
                    required: { value: true, message: "Campo Obligatorio" },
                    valueAsNumber: { value: true },
                  })}
                />
                <ErrorMessage
                  errors={errors2}
                  name="phoneCode"
                  render={({ message }) => (
                    <p className={classes.error}>{message}</p>
                  )}
                />
              </div>

              <div className={classes.modalButtonContainer}>
                <div className={classes.modalButtonCode}>
                  <ButtonLoading
                    text="VALIDAR"
                    //width={"calc(100vw * 150 / 1366)"}
                    loading={loadingCode}
                  />
                </div>
              </div>
            </form>
          </div>
        </div>
      )}
      {alert.show && (
        <Toast
          title={alert.title}
          message={alert.message}
          timer={() => setAlert(false)}
        />
      )}
    </Template>
  );
};

export default ClientScreen;
