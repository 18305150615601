import React from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import {
  HomeScreen,
  ClientScreen,
  InstructiveScreen,
  VerifyCodeScreen,
  SuccesMessageScreen,
  CuidadoScreen,
  TermsScreen,
  PromoQRScreen,
} from "../features";
import Stores from "../features/stores/Stores";

export default function App() {
  return (
    <Router>
      <Switch>
        <Route path="/client" component={ClientScreen} />
        <Route path="/cuidado" component={CuidadoScreen} />

        <Route path="/registrate" component={ClientScreen} />

        <Route path="/instructivo" component={InstructiveScreen} />

        <Route path="/stores" component={Stores} />
        <Route path="/promo" component={PromoQRScreen} />
        {/*  <Route path="/verifycode/:clientFemsa/:numberPhone/:franchise" render={({ match }) => {
                    console.log(match.params)
                    return (
                        <VerifyCodeScreen numberPhone={match.params.numberPhone} clientFemsa={match.params.clientFemsa} franchise={match.params.franchise} />
                    )
                }} /> */}
        <Route path="/verifycode" component={VerifyCodeScreen} />

        <Route path="/successMessage" component={SuccesMessageScreen} />

        <Route path="/term" component={TermsScreen} />

        <Route path="/" component={HomeScreen} />

        <Route exact path="*">
          <Redirect to="/" />
        </Route>
      </Switch>
    </Router>
  );
}
