import { makeStyles } from '@material-ui/core';

export const styles = makeStyles({
  button: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '50px',
    border: 'none',
    cursor: 'pointer',
    color: '#dd0c1c',
    fontSize: '1.2vw',
    height: 40,
    minWidth: '10vw',
    backgroundColor: '#FFFFFF',
    margin: '15px 5px 15px 0px',
    fontFamily: 'BebasNeue-Regular',
    textAlign: 'center',
    boxShadow: 'rgb(188 197 202) -2px -2px 1px 1px',
    "@media(orientation: portrait)": {
      width: '50vw',
      fontSize: '5vw',
    }
  },
})

export default styles