import { makeStyles } from "@material-ui/core";

export const styles = makeStyles({
  titleContainer: {
    textAlign: "center",
    position: "relative",
    background: "#fff",
    height: "calc(100vw * 201 / 1366)",
    "@media(max-width: 992px)": {
      height: "calc(100vw * 205 / 375)",
      paddingTop: "calc(100vw * 130 / 375)",
    },
  },
  title: {
    minHeight: "0vw",
    fontSize: "calc(100vw * 51 / 1366)",
    textAlign: "center",
    color: "#dd0b1c",
    position: "relative",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    fontFamily: "TCCC-UnityCondensed",
    fontWeight: "bold",
    "@media(max-width: 992px)": {
      fontSize: "calc(100vw * 32 / 375)",
    },
  },
  subTitle: {
    textAlign: "center",
    margintop: "calc(100vw * 33 / 1366)",
    marginBottom: "calc(100vw * 44 / 1366)",
    lineHeight: "calc(100vw * 40 / 1366)",
    "@media(max-width: 992px)": {
      marginBottom: "calc(100vw * 50 / 375)",
    },
  },
  fontSubTitle: {
    marginTop: "calc(100vw * 22 / 1366)",
    fontSize: "calc(100vw * 34 / 1366)",
    lineHeight: "calc(100vw * 40 / 1366)",
    fontWeight: "bold",
    color: "#dd0b1c",
    opacity: 1,
    fontFamily: "TCCC-UnityCondensed",
    "@media(max-width: 992px)": {
      fontSize: "calc(100vw * 28 / 375)",
      marginTop: "calc(100vw * 22 / 375)",
    },
  },
  form: {
    fontSize: "calc(100vw * 24 / 1366)",
    lineHeight: "calc(100vw * 40 / 1366)",
    color: "#dd0b1c",
    opacity: 1,
    fontFamily: "Bebas Neue",
    width: "calc(100vw * 400 / 1366)",
    margin: "0 auto",
    backgroundColor: "white",
    "@media(max-width: 992px)": {
      fontSize: "calc(100vw * 24 / 375)",
      width: "80%",
    },
  },

  franchiseContainer: {
    marginBottom: "calc(100vw * 22 / 1366)",
  },

  dropdownContentOn: {
    boxSizing: "border-box",
    border: " 1px solid #dd0b1c",
    width: "100%",
    borderRadius: 50,
    height: "calc(100vw * 43 / 1366)",
    color: "#000",
    paddingLeft: "calc(100vw * 10 / 1366)",

    "@media(max-width: 992px)": {
      marginTop: "calc(100vw * 12 / 375)",
      height: "calc(100vw * 40 / 375)",
      paddingLeft: "calc(100vw * 10 / 375)",
    },
  },
  error: {
    textTransform: "none",
    lineHeight: 0.9,
    fontFamily: "sans-serif",
    textAlign: "center",
    fontSize: "calc(100vw * 14 / 1366)",
    color: "#009688",
    margin: 0,
    marginTop: "calc(100vw * 8 / 1366)",
    "@media(max-width: 992px)": {
      fontSize: "calc(100vw * 10 / 375)",
      marginTop: "calc(100vw * 5 / 375)",
    },
  },
  clientMarginBottom: {
    marginBottom: "calc(100vw * 22 / 1366)",
  },
  clientNumberContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },

  label: {
    fontSize: "calc(100vw * 24 / 1366)",
    margin: 0,
    color: "#dd0b1c",
    "@media(max-width: 992px)": {
      fontSize: "calc(100vw * 24 / 375)",
      marginTop: "calc(100vw * 26 / 375)",
    },
  },
  sub_label: {
    fontSize: "calc(100vw * 20 / 1366)",
    "@media(max-width: 992px)": {
      fontSize: "calc(100vw * 20 / 375)",
    },
  },

  wspContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    marginBottom: "calc(100vw * 30 / 1366)",
  },

  prefix_input: {
    width: "calc(100vw * 80 / 1366)",
    color: "#757675",
    "@media(max-width: 992px)": {
      width: "calc(100vw * 73 / 375)",
    },
  },

  movil_label: {
    fontSize: "calc(100vw * 12 / 1366)",
    fontGamily: "Bebas Neue",
    lineHeight: 0.9,
    textTransform: "uppercase",
    color: "black",
    alignItems: "center",
    marginTop: "calc(100vw * 5 / 1366)",
    display: "block",
    textAlign: "center",

    "@media(max-width: 992px)": {
      fontSize: "calc(100vw * 12 / 375)",
    },
  },
  areacode_input: {
    width: "calc(100vw * 80 / 1366)",
    "@media(max-width: 992px)": {
      width: "calc(100vw * 73 / 375)",
      marginBottom: "calc(100vw * 3 / 375)",
    },
  },

  number_input: {
    width: "calc(100vw * 220 / 1366)",
    "@media(max-width: 992px)": {
      width: "calc(100vw * 148 / 375)",
      marginBottom: "calc(100vw * 3 / 375)",
    },
  },

  buttonContainer: {
    width: "calc(100vw * 400 / 1366)",
    display: "flex",
    justifyContent: "center",
    "@media(max-width: 992px)": {
      width: "calc(100vw * 300 / 375)",
      height: "calc(100vw * 50 / 375)",
      marginTop: "calc(100vw * 40 / 375)",
    },
  },
  button: {
    width: "calc(100vw * 210 / 1366)",
    "@media(max-width: 992px)": {
      width: "calc(100vw * 260 / 375)",
    },
  },

  modal: {
    width: "100%",
    height: "100%",
    backgroundColor: "#10101094",
    position: "fixed",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },

  modalDialog: {
    borderRadius: "15px",
    backgroundColor: "white",
    overflow: "auto",
    cursor: "default",
    width: "calc(100vw * 500 / 1366)",
    //Activa un scroll vertical en algunos tamaños de pantalla
    // height: "calc(100vw * 170/ 1366)",
    padding: "calc(100vw * 10 / 1366)",
    "@media(max-width: 992px)": {
      width: "calc(100vw * 300 / 375)",
      //Activa un scroll vertical en algunos tamaños de pantalla

      // height: "calc(100vw * 110/ 375)",
      padding: "calc(100vw * 5 / 375)",
    },
  },

  modalHeader: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    fontSize: "calc(100vw * 24 / 1366)",
    fontFamily: "BebasNeue-Regular",
    marginBottom: "calc(100vw * 15 / 1366)",
    "@media(max-width: 992px)": {
      fontSize: "calc(100vw * 16 / 375)",
      marginBottom: "calc(100vw * 8 / 375)",
    },
  },
  modalContent: {
    fontSize: " calc(100vw * 20 / 1366)",
    fontFamily: "BebasNeue-Regular",
    lineHeight: "calc(100vw * 24 / 1366)",
    marginBottom: "calc(100vw * 10 / 1366)",
    textAlign: "justify",
    "@media(max-width: 992px)": {
      fontSize: "calc(100vw * 14 / 375)",
      lineHeight: "calc(100vw * 18 / 375)",
      marginBottom: "calc(100vw * 8 / 375)",
    },
  },

  businessName: {
    fontSize: " calc(100vw * 20 / 1366)",
    fontFamily: "BebasNeue-Regular",
    color: "#de0b1c",
    "@media(max-width: 992px)": {
      fontSize: "calc(100vw * 14 / 375)",
    },
  },

  modalFooter: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-evenly",
    padding: "calc(100vw * 10 / 1366) 0",
    margin: 0,
    "@media(max-width: 992px)": {
      padding: "calc(100vw * 30 / 1366) 0",
    },
  },
  modalButton: {
    width: "calc(100vw * 80 / 1366)",
    fontFamily: "BebasNeue-Regular",
    backgroundColor: "#de0b1c",
    color: "white",
    cursor: "pointer",
    borderColor: "#de0b1c",
    borderRadius: "20px",
    border: "none",
    padding: 5,
    textDecoration: "none",
    outline: "none",
    fontSize: "calc(100vw * 24 / 1366)",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    "@media(max-width: 992px)": {
      width: "calc(100vw * 50/ 375)",
      height: "calc(100vw * 25 / 375)",
      fontSize: "calc(100vw * 14 / 375)",
    },
  },

  modalDialogCode: {
    width: "100%",
    height: "100%",
    backgroundColor: "#10101094",
    position: "fixed",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  modalContainerCode: {
    alignItems: "center",
    justifyContent: "center",
    alignContent: "center",
    fontSize: "calc(100vw * 24 / 1366)",
    lineHeight: "calc(100vw * 40 / 1366)",
    color: "#dd0b1c",
    opacity: 1,
    fontFamily: "Bebas Neue",
    borderRadius: "15px",
    backgroundColor: "white",
    cursor: "default",
    width: "calc(100vw * 400 / 1366)",
    height: "calc(100vw * 165/ 1366)",
    padding: "calc(100vw * 20 / 1366)",
    "@media(max-width: 992px)": {
      width: "calc(100vw * 300 / 375)",
      height: "calc(100vw * 200/ 375)",
      padding: "calc(100vw * 5 / 375)",
    },
  },
  codeContainer: {
    marginBottom: "calc(100vw * 30 / 1366)",
    "@media(max-width: 992px)": {},
  },
  labelCode: {
    fontSize: "calc(100vw * 24 / 1366)",
    margin: 0,
    color: "#dd0b1c",
    "@media(max-width: 992px)": {
      fontSize: "calc(100vw * 24 / 375)",
      marginTop: "calc(100vw * 26 / 375)",
      lineHeight: "calc(100vw * 24 / 375)",
    },
  },
  modalButtonContainer: {
    width: "calc(100vw * 400 / 1366)",
    display: "flex",
    justifyContent: "center",
    "@media(max-width: 992px)": {
      width: "calc(100vw * 300 / 375)",
      height: "calc(100vw * 50 / 375)",
      marginTop: "calc(100vw * 10 / 375)",
    },
  },
  modalButtonCode: {
    width: "calc(100vw * 400 / 1366)",
    display: "flex",
    justifyContent: "center",
    "@media(max-width: 992px)": {
      width: "calc(100vw * 150 / 375)",
      height: "calc(100vw * 50 / 375)",
    },
  },
});

export default styles;
