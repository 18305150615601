import { render } from '@testing-library/react';
import React from 'react';
import { Redirect } from 'react-router-dom';

const Stores = () => {

  const userAgent = navigator.userAgent || navigator.vendor || window.opera;

  const DetectOS = () => {
    let os = "";

    if (userAgent.includes("Android")) {
      os = "https://play.google.com/store/apps/details?id=com.cuponeraapp";
    } else if (userAgent.includes("iPhone")) {
      os = "https://apps.apple.com/ar/app/canal-clientes-coca-cola/id1530038867";
    } else {
      os = "https://canalcoca-cola.com.ar";
    }
    window.location.href = os;
  }

  DetectOS();

  return (
    <div> Browser Name </div>
  )
}

export default Stores